import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "../components/layout.scss";
import Layout from "../components/layout";
import styled from "styled-components";

const TextBlock = styled.div`
  text-align: center;
`;

const ImageBlock = styled.div`
  text-align: center;
  margin-top: -20px;
  margin-bottom: 40px;
`;

const ContentBlock = styled.div`
  margin: 40px;
  text-align: center;
`;

const ExtraPaddingBottom = styled.div`
  padding-bottom: 10px;
`;

const AboutUs = () => {
  return (
    <Layout>
      <div id="content" className="site-content">
        <div className="content-inner">
          <div className="">
            <div className="row content-row">
              <div id="primary" className="content-area col-12">
                <main id="main" className="site-main">
                  <article
                    id="post-18"
                    class="post-18 page type-page status-publish hentry"
                  >
                    <div class="entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="18"
                        class="elementor elementor-18"
                        data-elementor-settings="[]"
                      >
                        <div class="elementor-section-wrap">
                          <section
                            class="elementor-section elementor-top-section elementor-element elementor-element-69791ad elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="69791ad"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                          >
                            <div class="elementor-container elementor-column-gap-default ">
                              <div
                                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8f1c281"
                                data-id="8f1c281"
                                data-element_type="column"
                              >
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    class="elementor-element elementor-element-52bae3da primary-color elementor-widget elementor-widget-cms_heading"
                                    data-id="52bae3da"
                                    data-element_type="widget"
                                    data-widget_type="cms_heading.default"
                                  >
                                    <div class="">
                                      <div class="cms-heading-wrapper cms-heading-layout1 ">
                                        <h3 class="custom-heading">
                                          <span>About Us</span>
                                        </h3>
                                      </div>{" "}
                                    </div>
                                  </div>

                                  <div
                                    class="elementor-element elementor-element-7893195d elementor-widget elementor-widget-cms_heading"
                                    data-id="7893195d"
                                    data-element_type="widget"
                                    data-widget_type="cms_heading.default"
                                  >
                                    <div class="">
                                      <div class="cms-heading-wrapper cms-heading-layout1 ">
                                        <h3 class="custom-heading">
                                          <span>What is Toddler Talk?</span>
                                        </h3>

                                        <div class="custom-heading-description mb-3">
                                          As a parent, you want to know that
                                          your child is developing speech and
                                          language skills on time. If they’re
                                          falling behind, you want to do
                                          everything in your power to get them
                                          on track. I’m Stephanie Keffer, and I
                                          have worked with hundreds of toddlers
                                          and their parents in a clinical
                                          setting. I know it’s not a lack of
                                          will that’s standing in the way of
                                          improving your child’s language
                                          development, it’s a lack of
                                          resources. {" "}
                                        </div>
                                        <ExtraPaddingBottom />

                                        <div class="custom-heading-description mb-3">
                                          That’s why I created Toddler Talk, an
                                          easy way for you to take your
                                          toddler’s speech and language
                                          development into your own hands. It’s
                                          not a replacement for speech therapy -
                                          I’m a licensed speech therapist and I
                                          know first hand the value of
                                          professional help. Toddler Talk is a
                                          tool to help fill the gap during your
                                          child’s crucial early development when
                                          therapy is unavailable, or just
                                          impractical.{" "}
                                        </div>
                                        <ExtraPaddingBottom />
                                        <div class="custom-heading-description mb-3">
                                          It starts with a free online
                                          assessment. The 10-minute
                                          questionnaire is designed to evaluate
                                          your child’s development in
                                          comprehension, expression, and their
                                          readiness to learn new concepts. The
                                          findings from your assessment compare
                                          your child’s development directly with
                                          peers of the same age.{" "}
                                        </div>
                                        <ExtraPaddingBottom />

                                        <div class="custom-heading-description mb-3">
                                          If your child shows any signs of
                                          developmental delays in their
                                          communication skills, we absolutely
                                          recommend you speak to your
                                          pediatrician about a referral to a
                                          speech therapist. But therapy is not a
                                          magic wand that will fix your child’s
                                          development in 30-60 minutes per week.
                                          Every day is critical at this age, and
                                          every day is an opportunity for your
                                          child to learn. {" "}
                                        </div>
                                        <ExtraPaddingBottom />

                                        <div class="custom-heading-description mb-3">
                                          Toddler Talk provides you and your
                                          child with a customized Action Plan
                                          that prioritizes the skills most
                                          important to their individual
                                          development. These plans were
                                          developed by speech
                                          language-pathologists with a combined
                                          37 years of experience working with
                                          young children, and they are designed
                                          to put you, the parent, in control of
                                          your toddler’s day-to-day language
                                          development.{" "}
                                        </div>
                                        <ExtraPaddingBottom />

                                        <div class="custom-heading-description mb-3">
                                          Your plan includes concise written and
                                          video tutorials on how to build your
                                          child’s communication skills during
                                          your normal everyday activities.
                                          Whether you’re eating dinner, getting
                                          dressed, or just playing, you can
                                          interact with your child in a way that
                                          will set them up for success in future
                                          therapy, and in the real world.
                                          Toddler Talk also provides tools to
                                          monitor and track progress, and
                                          automatically updates its focus as
                                          your child masters new skills.{" "}
                                        </div>
                                        <ExtraPaddingBottom />

                                        <div class="custom-heading-description mb-3">
                                          You don’t have to get a Master’s
                                          Degree in speech or quit your job to
                                          positively impact your child’s future.
                                          You can start Toddler Talk right away
                                          by completing our custom
                                          questionnaire.{" "}
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          {/* <section
                            class="elementor-section elementor-top-section elementor-element elementor-element-b2c018b elementor-section-stretched bg-video-section elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="b2c018b"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                          >
                            <div class="elementor-background-overlay"></div>

                            <div class="elementor-container elementor-column-gap-default ">
                              <div
                                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1e3e7cb"
                                data-id="1e3e7cb"
                                data-element_type="column"
                              >
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    class="elementor-element elementor-element-9531fce elementor-widget elementor-widget-cms_heading"
                                    data-id="9531fce"
                                    data-element_type="widget"
                                    data-widget_type="cms_heading.default"
                                  >
                                    <div class="">
                                      <div class="cms-heading-wrapper cms-heading-layout1 ">
                                        <div class="custom-subheading">
                                          Click to watch
                                        </div>

                                        <h3 class="custom-heading">
                                          <span>Our Story</span>
                                        </h3>
                                      </div>{" "}
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-ac4fa74 elementor-widget elementor-widget-cms_video_player"
                                    data-id="ac4fa74"
                                    data-element_type="widget"
                                    data-widget_type="cms_video_player.default"
                                  >
                                    <div class="">
                                      <div class="cms-video-player layout5 wow zoomInDown">
                                        <div class="inner-layout">
                                          <div class="wp-box-meta">
                                            <a
                                              class="btn-video"
                                              href="https://www.youtube.com/watch?v=SF4aHwxHtZ0"
                                            >
                                              <StaticImage
                                                width="136"
                                                height="136"
                                                src="../images/button-video.png"
                                                class="attachment-full size-full"
                                                alt=""
                                                loading="lazy"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section> */}
                          <section
                            class="elementor-section elementor-top-section elementor-element elementor-element-69791ad elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="69791ad"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                          >
                            <div class="elementor-container elementor-column-gap-default ">
                              <div
                                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8f1c281"
                                data-id="8f1c281"
                                data-element_type="column"
                              >
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    class="elementor-element elementor-element-52bae3da primary-color elementor-widget elementor-widget-cms_heading"
                                    data-id="52bae3da"
                                    data-element_type="widget"
                                    data-widget_type="cms_heading.default"
                                  >
                                    <div class="">
                                      <div class="cms-heading-wrapper cms-heading-layout1 ">
                                        <h3 class="custom-heading">
                                          <span>
                                            Meet the Toddler Talk Speech Therapy
                                            Team!
                                          </span>
                                        </h3>
                                      </div>{" "}
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-7893195d elementor-widget elementor-widget-cms_heading"
                                    data-id="7893195d"
                                    data-element_type="widget"
                                    data-widget_type="cms_heading.default"
                                  >
                                    <div class="">
                                      <div class="cms-heading-wrapper cms-heading-layout1 ">
                                        <h3 class="custom-heading">
                                          <span>Who are we?</span>
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-9be0e04 elementor-widget elementor-widget-cms_team_grid"
                                    data-id="9be0e04"
                                    data-element_type="widget"
                                    data-widget_type="cms_team_grid.default"
                                  >
                                    <div class="">
                                      <div class="cms-grid cms-team-grid1">
                                        <div
                                          class="cms-grid-inner cms-grid-masonry row"
                                          data-gutter="7"
                                        >
                                          <div class="grid-item col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                                            <div class="team-item">
                                              <div class="item--inner ">
                                                <div class="item--image">
                                                  <StaticImage
                                                    width="800"
                                                    height="800"
                                                    src="../images/Stephanie_Keffer.png"
                                                    class="attachment-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                                <div class="item-holder">
                                                  <h3 class="item--title">
                                                    <a href="#" target="_blank">
                                                      Stephanie Keffer{" "}
                                                    </a>
                                                  </h3>
                                                  <div class="item--position">
                                                    MS, CCC-SLP
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="grid-item col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                                            <div class="team-item">
                                              <div class="item--inner ">
                                                <div class="item--image">
                                                  <StaticImage
                                                    width="800"
                                                    height="800"
                                                    src="../images/Stephanie-Vader.png"
                                                    class="attachment-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                                <div class="item-holder">
                                                  <h3 class="item--title">
                                                    <a href="#" target="_blank">
                                                      Stephanie Burgener-Vader{" "}
                                                    </a>
                                                  </h3>
                                                  <div class="item--position">
                                                    MS, CCC-SLP
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="grid-item col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                                            <div class="team-item">
                                              <div class="item--inner ">
                                                <div class="item--image">
                                                  <StaticImage
                                                    width="800"
                                                    height="800"
                                                    src="../images/Melissa_Sartori.png"
                                                    class="attachment-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                                <div class="item-holder">
                                                  <h3 class="item--title">
                                                    <a href="#" target="_blank">
                                                      Melissa Sartori{" "}
                                                    </a>
                                                  </h3>
                                                  <div class="item--position">
                                                    MA, CCC-SLP
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="grid-item col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                                            <div class="team-item">
                                              <div class="item--inner ">
                                                <div class="item--image">
                                                  <StaticImage
                                                    width="800"
                                                    height="800"
                                                    src="../images/Yvette_Fraire.png"
                                                    class="attachment-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                                <div class="item-holder">
                                                  <h3 class="item--title">
                                                    <a href="#" target="_blank">
                                                      Yvette Fraire Bostick{" "}
                                                    </a>
                                                  </h3>
                                                  <div class="item--position">
                                                    MS, CCC-SLP
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            class="elementor-section elementor-top-section elementor-element  elementor-section-stretched elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default box-shadow-team"
                            data-id="d3e7fd7"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                          >
                            <div class="elementor-container elementor-column-gap-default ">
                              <div
                                class="elementor-column elementor-top-column elementor-element elementor-element-788d84a"
                                data-id="788d84a"
                                data-element_type="column"
                              >
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    class="elementor-element elementor-element-645103a elementor-widget elementor-widget-cms_heading"
                                    data-id="645103a"
                                    data-element_type="widget"
                                    data-widget_type="cms_heading.default"
                                  >
                                    <div class="">
                                      <div class="cms-heading-wrapper cms-heading-layout1 ">
                                        <h3 class="custom-heading">
                                          <span>
                                            Stephanie Keffer, MS, CCC-SLP
                                          </span>
                                        </h3>

                                        <div class="custom-heading-description mb-3">
                                          Stephanie Keffer is an ASHA certified
                                          speech-language pathologist, licensed
                                          in California. She has 4 years of
                                          experience working with children and
                                          their families to evaluate and treat
                                          children with speech and language
                                          disorders. Stephanie collaborates with
                                          both monolingual and bilingual
                                          families (Spanish/English) to address
                                          each families unique communication
                                          goals and needs. Additional areas of
                                          expertise include: early intervention
                                          (Birth-3), autism spectrum disorder,
                                          acquired brain injury, assistive
                                          technology, and speech and language
                                          treatment as part of a
                                          multidisciplinary team to support
                                          children who are hospitalized.
                                        </div>

                                        <div class="custom-heading-description mb-3">
                                          Stephanie earned her BS (majored in
                                          Speech-Language Pathology) at Boston
                                          University. She then went on to earn
                                          her MS in Speech-Language Pathology
                                          from Columbia University in New York
                                          City. She has experience working as a
                                          speech therapist with children in both
                                          the inpatient and outpatient settings.
                                          She also has participated in research
                                          related to speech language pathology.
                                          At this time, Stephanie has dedicated
                                          herself fully to her vision of
                                          increasing access to care for all
                                          young children in need of speech
                                          therapy.{" "}
                                        </div>

                                        <div class="custom-heading-description mb-3">
                                          When not hard at work, Stephanie
                                          enjoys spending time with her friends
                                          family. She lives in California where
                                          she enjoys nature year round. She
                                          enjoys surfing, swimming, skating,
                                          biking, running, hiking, and skiing.
                                          Stephanie also loves gardening,
                                          reading, and preparing delicious
                                          meals. She is the proud auntie and
                                          loves visiting her family in North
                                          Carolina!{" "}
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-0ae1416 elementor-widget elementor-widget-spacer"
                                    data-id="0ae1416"
                                    data-element_type="widget"
                                    data-widget_type="spacer.default"
                                  >
                                    <div class="">
                                      <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div
                                class="elementor-column elementor-top-column elementor-element elementor-element-7809d69"
                                data-id="7809d69"
                                data-element_type="column"
                              >
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    class="elementor-element elementor-element-7e91c8d elementor-widget elementor-widget-cms_banner"
                                    data-id="7e91c8d"
                                    data-element_type="widget"
                                    data-widget_type="cms_banner.default"
                                  >
                                    <div class="">
                                      <div class="cms-banner layout1">
                                        <div class="img-bg wow fadeInRight">
                                          <StaticImage
                                            placeholder="none"
                                            width="379"
                                            height="511"
                                            src="../images/leader-team.png"
                                            className="attachment-full"
                                            alt=""
                                            loading="lazy"
                                          />{" "}
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </section>
                          <section
                            class="elementor-section elementor-top-section elementor-element  elementor-section-stretched elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="d3e7fd7"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                          >
                            <div class="elementor-container elementor-column-gap-default ">
                              <div
                                class="elementor-column elementor-top-column elementor-element elementor-element-788d84a"
                                data-id="788d84a"
                                data-element_type="column"
                              >
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    class="elementor-element elementor-element-645103a elementor-widget elementor-widget-cms_heading"
                                    data-id="645103a"
                                    data-element_type="widget"
                                    data-widget_type="cms_heading.default"
                                  >
                                    <div class="">
                                      <div class="cms-heading-wrapper cms-heading-layout1 ">
                                        <h3 class="custom-heading">
                                          <span>
                                            Stephanie Burgener-Vader, MS,
                                            CCC-SLP
                                          </span>
                                        </h3>

                                        <div class="custom-heading-description mb-3">
                                          Stephanie Burgener-Vader is a ASHA
                                          certified speech-language pathologist
                                          practicing in California. She has over
                                          12 years of experience working with
                                          young children and their families to
                                          develop early language and
                                          communication skills. She additionally
                                          has specialties in motor speech
                                          disorders, acquired brain injury,
                                          genetic disorders, and autism spectrum
                                          disorders.
                                        </div>

                                        <div class="custom-heading-description mb-3">
                                          Stephanie has a BA in Socio-Cultural
                                          Linguistics as well as a MA in
                                          Speech-Language-Hearing Sciences. She
                                          has worked in pediatrics both at
                                          private practice and hospital
                                          settings. She currently works at a
                                          children’s hospital providing speech
                                          and language intervention to children
                                          in outpatient and inpatient settings.{" "}
                                        </div>

                                        <div class="custom-heading-description mb-3">
                                          Stephanie lives in California with her
                                          husband, two children, and large
                                          squishy orange cat named Thorvald. 
                                          She enjoys baking, cooking, reading,
                                          and gardening. Her favorite thing to
                                          do on the weekend is relax, but she is
                                          typically cleaning or fixing things
                                          instead. She and her children can
                                          usually be found making forts out of
                                          random things around the house and
                                          trying to convince the cat to come
                                          inside. She believes that developing
                                          an imagination is a critical childhood
                                          skill and encourages creative play
                                          both at home and in her clinical
                                          practice.{" "}
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-0ae1416 elementor-widget elementor-widget-spacer"
                                    data-id="0ae1416"
                                    data-element_type="widget"
                                    data-widget_type="spacer.default"
                                  >
                                    <div class="">
                                      <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div
                                class="elementor-column elementor-top-column elementor-element elementor-element-7809d69"
                                data-id="7809d69"
                                data-element_type="column"
                              >
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    class="elementor-element elementor-element-7e91c8d elementor-widget elementor-widget-cms_banner"
                                    data-id="7e91c8d"
                                    data-element_type="widget"
                                    data-widget_type="cms_banner.default"
                                  >
                                    <div class="">
                                      <div class="cms-banner layout1">
                                        <div class="img-bg wow fadeInRight">
                                          <StaticImage
                                            placeholder="none"
                                            width="379"
                                            height="511"
                                            src="../images/leader-team.png"
                                            className="attachment-full"
                                            alt=""
                                            loading="lazy"
                                          />{" "}
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </section>
                          <section
                            class="elementor-section elementor-top-section elementor-element  elementor-section-stretched elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="d3e7fd7"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                          >
                            <div class="elementor-container elementor-column-gap-default ">
                              <div
                                class="elementor-column elementor-top-column elementor-element elementor-element-788d84a"
                                data-id="788d84a"
                                data-element_type="column"
                              >
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    class="elementor-element elementor-element-645103a elementor-widget elementor-widget-cms_heading"
                                    data-id="645103a"
                                    data-element_type="widget"
                                    data-widget_type="cms_heading.default"
                                  >
                                    <div class="">
                                      <div class="cms-heading-wrapper cms-heading-layout1 ">
                                        <h3 class="custom-heading">
                                          <span>
                                            Melissa Sartori, MA, CCC-SLP
                                          </span>
                                        </h3>

                                        <div class="custom-heading-description mb-3">
                                          Melissa Sartori is an ASHA certified
                                          speech-language pathologist, licensed
                                          and practicing in California. She has
                                          11 years of experience evaluating and
                                          treating both monolingual and
                                          bilingual children with speech and
                                          language disorders, and working with
                                          their families. Additional areas of
                                          expertise include: early intervention
                                          (Birth-3), autism spectrum disorder,
                                          hearing loss, assistive technology,
                                          and feeding disorders.
                                        </div>

                                        <div class="custom-heading-description mb-3">
                                          Melissa Sartori is an ASHA certified
                                          speech-language pathologist, licensed
                                          and practicing in California. She has
                                          11 years of experience evaluating and
                                          treating both monolingual and
                                          bilingual children with speech and
                                          language disorders, and working with
                                          their families. Additional areas of
                                          expertise include: early intervention
                                          (Birth-3), autism spectrum disorder,
                                          hearing loss, assistive technology,
                                          and feeding disorders.
                                        </div>

                                        <div class="custom-heading-description mb-3">
                                          Melissa earned both her BS (majored in
                                          Speech-Language Pathology and Spanish)
                                          and MS in Speech-Language Pathology
                                          from the University of the Pacific, in
                                          Stockton California. Since then, she
                                          has worked across settings including:
                                          the local school district, a private
                                          practice, and a children’s hospital.
                                          She is currently working at the
                                          children’s hospital in San Diego,
                                          California, primarily in the
                                          outpatient setting. 
                                        </div>

                                        <div class="custom-heading-description mb-3">
                                          When not working as a speech-language
                                          pathologist, Melissa enjoys spending
                                          time with her family. She lives in
                                          California with her nurse-practitioner
                                          husband and their preschool aged
                                          daughter. They also have another baby
                                          girl on the way. With her family time,
                                          she enjoys supporting elaborate
                                          pretend play schemes, playing games,
                                          going on playdates, and making a huge
                                          mess of the house! She does not enjoy
                                          the cleanup of said house disasters
                                          though. During downtime, she also
                                          enjoys doing crafts and scrapbooking,
                                          game nights, reading and taking naps
                                          when she has time to herself.
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-0ae1416 elementor-widget elementor-widget-spacer"
                                    data-id="0ae1416"
                                    data-element_type="widget"
                                    data-widget_type="spacer.default"
                                  >
                                    <div class="">
                                      <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div
                                class="elementor-column elementor-top-column elementor-element elementor-element-7809d69"
                                data-id="7809d69"
                                data-element_type="column"
                              >
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    class="elementor-element elementor-element-7e91c8d elementor-widget elementor-widget-cms_banner"
                                    data-id="7e91c8d"
                                    data-element_type="widget"
                                    data-widget_type="cms_banner.default"
                                  >
                                    <div class="">
                                      <div class="cms-banner layout1">
                                        <div class="img-bg wow fadeInRight">
                                          <StaticImage
                                            placeholder="none"
                                            width="379"
                                            height="511"
                                            src="../images/leader-team.png"
                                            className="attachment-full"
                                            alt=""
                                            loading="lazy"
                                          />{" "}
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </section>
                          <section
                            class="elementor-section elementor-top-section elementor-element  elementor-section-stretched elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="d3e7fd7"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                          >
                            <div class="elementor-container elementor-column-gap-default ">
                              <div
                                class="elementor-column elementor-top-column elementor-element elementor-element-788d84a"
                                data-id="788d84a"
                                data-element_type="column"
                              >
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    class="elementor-element elementor-element-645103a elementor-widget elementor-widget-cms_heading"
                                    data-id="645103a"
                                    data-element_type="widget"
                                    data-widget_type="cms_heading.default"
                                  >
                                    <div class="">
                                      <div class="cms-heading-wrapper cms-heading-layout1 ">
                                        <h3 class="custom-heading">
                                          <span>
                                            Yvette Fraire Bostick, MS, CCC-SLP
                                          </span>
                                        </h3>

                                        <div class="custom-heading-description mb-3">
                                          Yvette Fraire Bostick is an ASHA
                                          certified speech-language pathologist,
                                          licensed in Texas. She has 11 years of
                                          experience evaluating and treating
                                          children with speech and language
                                          disorders, and working with their
                                          families. Her areas of expertise
                                          include early intervention (Birth-3),
                                          bilingual/multilingual families,
                                          children with autism spectrum
                                          disorder, working with children with
                                          hearing loss, treating oral-motor
                                          speech disorders, and working on
                                          multidisciplinary teams to provide
                                          evaluations and support for children
                                          with complex needs and their
                                          caregivers. 
                                        </div>

                                        <div class="custom-heading-description mb-3">
                                          Yvette earned her BS in Communication
                                          Sciences and disorders from the
                                          University of Texas at Austin and her
                                          MS in Speech-Language Pathology from
                                          the University of Texas at Dallas.
                                          Since then, she has worked across
                                          settings including: local school
                                          districts, private practices, Home
                                          Health, Texas Early Childhood
                                          Intervention Services, and the
                                          children’s hospital in San Diego. {" "}
                                        </div>

                                        <div class="custom-heading-description mb-3">
                                          Yvette believes in working on the
                                          whole child. It is her passion to
                                          support families and caregivers to
                                          have the tools and resources needed to
                                          help their child. It brings her so
                                          much joy to see families learn how to
                                          play together and learn together. {" "}
                                        </div>

                                        <div class="custom-heading-description mb-3">
                                          When not working with Toddler Talk,
                                          Yvette enjoys spending time with her
                                          friends and family. Yvette lives in
                                          Texas with her husband, who serves in
                                          the United State Marine Corps, and
                                          their 10 month old son who will be a
                                          big brother in May of 2021. She enjoys
                                          going on daily adventures with her son
                                          to the park, Costco, Gymboree, and
                                          outside to play with the neighbors.
                                          She enjoys seeing her son learn new
                                          things and develop new skills
                                          everyday. When she has time to
                                          herself, Yvette enjoys anything
                                          related to food, shopping at local
                                          markets, and going on lunch dates with
                                          girlfriends. {" "}
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-0ae1416 elementor-widget elementor-widget-spacer"
                                    data-id="0ae1416"
                                    data-element_type="widget"
                                    data-widget_type="spacer.default"
                                  >
                                    <div class="">
                                      <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div
                                class="elementor-column elementor-top-column elementor-element elementor-element-7809d69"
                                data-id="7809d69"
                                data-element_type="column"
                              >
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    class="elementor-element elementor-element-7e91c8d elementor-widget elementor-widget-cms_banner"
                                    data-id="7e91c8d"
                                    data-element_type="widget"
                                    data-widget_type="cms_banner.default"
                                  >
                                    <div class="">
                                      <div class="cms-banner layout1">
                                        <div class="img-bg wow fadeInRight">
                                          <StaticImage
                                            placeholder="none"
                                            width="379"
                                            height="511"
                                            src="../images/leader-team.png"
                                            className="attachment-full"
                                            alt=""
                                            loading="lazy"
                                          />{" "}
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
